var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.eGiftBanner = {
    /**
     * Flag to ensure this JS is only attached once.
     * @type {boolean}
     */
    attached: false,

    /** removes the redemption banner cookie
     * @return {boolean} - if successful
     */
    removeBannerCookie: function () {
      return generic.cookie('egift_banner_on', '0', {
        path: '/',
        expires: 0
      });
    },

    /** detects if the banner cookie is already set,
     * and confirms we're not in account or checkout
     * if so, displays the banner
     * @return {boolean} -  true if the cookie is detected
     */
    detectBannerCookie: function () {
      var found = false;
      var pathArray = window.location.pathname.split('/');
      var in_pg = pathArray[1] === 'account' || pathArray[1] === 'checkout';

      if (generic.cookie('egift_banner_on') === 1 && !in_pg) {
        found = true;
        this.fireRedemptionBanner();
      }

      return found;
    },

    /** fires the redemption banner telling the user they're redeeming
     * the egift
     * @return {boolean} - if successful
     */
    fireRedemptionBanner: function () {
      site.template = site.template || {};
      var self = this;
      var $siteHeader = $('header.site-header');
      var egiftBannerHTML = site.template.get({
        name: 'egift_redemption_banner'
      });
      var $egiftBanner = $(egiftBannerHTML).appendTo($siteHeader).slideDown();
      var $egiftBannerClose = $egiftBanner.find('.js-close-site-banner');

      $egiftBannerClose.off().on('click', function () {
        $egiftBanner.slideUp();
        self.removeBannerCookie();
      });

      return true;
    },

    /**
     * Standard method for attaching the template's JS.
     * @param {Object} context - the DOM scope, usually document.
     * @param {Object} settings - useful collection of site info.
     * @return {none} none
     */
    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      this.detectBannerCookie();
    }
  };
})(jQuery);
